import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import ProductDetail from '../features/productDetail';

const ProductDetailPage = () => {
  return (
    <Box style={{ position: 'relative', top: 0, backgroundColor: '#F8F8F8' }}>
      <img
        style={{ width: '100%', height: 'auto' }}
        src='/images/test-3.png'
        alt='logo'
      />
      <Typography
        style={{
          fontSize: 60,
          position: 'absolute',
          top: '80px',

          textAlign: 'center',
          width: '100%',
          color: 'white',
          letterSpacing: 20,
        }}
      >
        PRODUCTS DETAIL
      </Typography>
      <ProductDetail />
    </Box>
  );
};

export default ProductDetailPage;
