import React from 'react';

import Box from '@mui/material/Box';

import { Typography } from '@mui/material';

const ProductCare = () => {
  const styles = {
    rootTextAboutUs: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginLeft: '10%',
      marginRight: '10%',
      marginTop: 10,
      marginBottom: 30,
    },
    divider: {
      width: 400,
      height: '0px',
      border: '1px solid rgba(0, 0, 0, 0.3)',
      marginBottom: 40,
    },
    typography1: {
      fontWeight: 'bold',
      fontSize: '26px',
      color: 'rgba(0, 0, 0, 0.56)',
      marginBottom: 10,
    },
    typography2: {
      fontSize: '12px',
      color: 'rgba(0, 0, 0, 0.56)',
      marginBottom: 10,
      textAlign: 'left',
      width: '90%',
    },
  };
  const renderTextAboutUs = () => {
    return (
      <div style={styles.rootTextAboutUs}>
        <Typography style={styles.typography1}>TEAK FURNITURE CARE</Typography>
        <div style={styles.divider} />
        <Typography style={styles.typography2}>
          As part of our commitment to protecting the environment and sustaining
          long term supplies, we only use teak wood harvested from established
          and controlled plantations. Here are the major points, why you should
          select Warner Levitzson Furniture as your source of teak outdoor
          furniture :
        </Typography>
        <Typography style={styles.typography2} paragraph>
          {'\u2022'} All joints are made with mortise and tenon, which is the
          strongest method. These connections are secured using premium outdoor
          glue and teak wood dowels.
        </Typography>
        <Typography style={styles.typography2} paragraph>
          {'\u2022'} Stress points are double-doweled for extra strength.
        </Typography>
        <Typography style={styles.typography2} paragraph>
          {'\u2022'} When hardware is required, premium solid brass and
          stainless steel screws and bolts are used.
        </Typography>
        <Typography style={styles.typography2} paragraph>
          {'\u2022'} From the beginning of production, furniture is constructed
          with durability, quality and value in mind.
        </Typography>{' '}
        <Typography style={styles.typography2} paragraph>
          {'\u2022'} We do not use cost-cutting measures such as lower grade
          teak, thin pieces of light weight teak, or poor-quality craftsmanship
          , hardware and outdoor glue.
        </Typography>{' '}
        <Typography style={styles.typography2} paragraph>
          {'\u2022'} Natural fine hand-sanded and hand-buffing satin finish is
          standard for all of our teak outdoor furniture.
        </Typography>{' '}
        <Typography style={styles.typography2} paragraph>
          {'\u2022'} We do not use teak oil or any other coloring method to hide
          imperfections in our teak outdoor furniture.
        </Typography>{' '}
        <Typography style={styles.typography2} paragraph>
          {'\u2022'} Only the heart of teak log is used which leads to Premium
          Grade &quot;A&quot; Teak Wood Furniture.
        </Typography>{' '}
        <Typography style={styles.typography2} paragraph>
          {'\u2022'} We are proud to provide you with more teak for your money;
          that is why we display the weight of each piece of the furniture.
        </Typography>{' '}
        <Typography style={styles.typography2} paragraph>
          {'\u2022'} 1.18&quot; ( 3cm) thick is our standard dining table top,
          we also offer X-Thick table top which is 1.38&quot; ( 3.5cm ),
          XX-Thick is 1.57&quot; (4cm), and XXX-Thick is 1.97&quot; ( 5cm ).
        </Typography>{' '}
        <Typography style={styles.typography2} paragraph>
          {'\u2022'} Sturdy construction paired with &quot;beefier&quot; high
          quality timber ensure that each piece of furniture will endure a
          lifetime of use.
        </Typography>{' '}
        <Typography style={styles.typography2} paragraph>
          {'\u2022'} With well over 250 different furniture in stock year round
          in Santa Ana, California U.S.A. and in Semarang, Indonesia; we provide
          custom designs, made to order sizes, one of a kind commercial
          projects, exclusive designs exclusively built for your store/stores.
        </Typography>
      </div>
    );
  };

  return (
    <Box style={{ position: 'relative', top: 0 }}>
      <img
        style={{ width: '100%', height: 'auto' }}
        src='/images/test-3.png'
        alt='logo'
      />
      <Typography
        style={{
          fontSize: 60,
          position: 'absolute',
          top: '80px',
          textAlign: 'center',
          width: '100%',
          color: 'white',
          letterSpacing: 20,
        }}
      >
        PRODUCT CARE
      </Typography>
      {renderTextAboutUs()}
    </Box>
  );
};

export default ProductCare;
