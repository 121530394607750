import React from 'react';
import Typography from '@mui/material/Typography';

const Footer = () => {
  const styles = {
    typography1: {
      fontWeight: 'bold',
      fontSize: '26px',
      color: 'rgba(0, 0, 0, 0.56)',
      marginBottom: 20,
    },
    typography2: {
      fontSize: '16px',
      color: 'rgba(0, 0, 0, 0.56)',
      marginBottom: 10,
    },
    typography3: {
      fontSize: '16px',
      color: 'rgba(0, 0, 0, 0.56)',
      width: '70%',
      marginBottom: 10,
    },
  };

  const renderLogo = () => {
    return (
      <div style={{ display: 'flex' }}>
        <img
          style={{ width: 50, height: 50 }}
          src='/images/logo192.png'
          alt='logo'
        />
        <div>
          <Typography style={styles.typography1}>WARNER LEVITZSON ®</Typography>
          <Typography style={styles.typography3}>
            2008-C Yale Street Santa Ana California 92704
          </Typography>
          <Typography style={styles.typography3}>
            Phone: 714.206.8884 Email: wlteak@yahoo.com
          </Typography>
        </div>
      </div>
    );
  };

  const renderCorporateInfo = () => {
    return (
      <div>
        <Typography style={styles.typography1}>CORPORATE INFO</Typography>
        <Typography style={styles.typography2}>ABOUT US </Typography>
        <Typography style={styles.typography2}>TESTIMONIAL </Typography>
        <Typography style={styles.typography2}>CAREERS </Typography>
        <Typography style={styles.typography2}>EXTENDED SERVICES </Typography>
        <Typography style={styles.typography2}>PARTNERSHIP </Typography>
      </div>
    );
  };

  const renderSupport = () => {
    return (
      <div>
        <Typography style={styles.typography1}>SUPPORT</Typography>
        <Typography style={styles.typography2}>FAQ </Typography>
        <Typography style={styles.typography2}>CONTACT US </Typography>
      </div>
    );
  };

  return (
    <div
      style={{
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: '15%',
        paddingRight: '15%',
        paddingTop: 30,
        paddingBottom: 30,
      }}
    >
      {renderLogo()}
      {renderCorporateInfo()}
      {renderSupport()}
    </div>
  );
};

export default Footer;
