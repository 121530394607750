import React from 'react';

import Box from '@mui/material/Box';

import ProductCard from '../features/productCard';
import CategoryCard from '../features/categoryCard/CategoryCard';
import PortofolioCard from '../features/portofolioCard/PortofolioCard';
import { Typography } from '@mui/material';

const Home = () => {
  const styles = {
    rootTextOurFuniture: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    divider: {
      width: 300,
      height: '0px',
      border: '1px solid rgba(0, 0, 0, 0.3)',
    },
    typography1: {
      fontWeight: 'bold',
      fontSize: '26px',
      color: 'rgba(0, 0, 0, 0.56)',
      marginBottom: 10,
    },
    typography2: {
      fontSize: '24px',
      color: 'rgba(0, 0, 0, 0.56)',
      marginBottom: 10,
      textAlign: 'center',
      width: '90%',
    },
  };
  const renderTextOurFuniture = () => {
    return (
      <div style={styles.rootTextOurFuniture}>
        <Typography style={styles.typography1}>OUR FURNITURE</Typography>
        <div style={styles.divider} />

        <Typography style={styles.typography2}>
          Our quality teak outdoor and garden furniture products are most
          commonly finished in what is called a &quot;Natural&quot; finish. In
          this process, the wood is fine sanded down to a smooth feel with fine
          grit sandpaper and then several coats of teak oil is applied.
        </Typography>
      </div>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <ProductCard />
      <div style={{ marginTop: 40 }} />
      <CategoryCard />
      <div style={{ marginTop: 40 }} />
      <PortofolioCard />
      <div style={{ marginTop: 40 }} />
      {renderTextOurFuniture()}
      <div style={{ marginTop: 40 }} />
    </Box>
  );
};

export default Home;
