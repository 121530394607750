import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import RouteWithLayout from '../components/RouteWithLayout';
import { Main as MainLayout } from '../layout';
import HomePage from '../../pages/Home';
import AboutUsPage from '../../pages/AboutUs';
import ProductsPage from '../../pages/Products';
import ProductDetailPage from '../../pages/ProductDetail';
import ProductCarePage from '../../pages/ProductCare';
import RequestCatalogPage from '../../pages/RequestCatalog';
import ContactUsPage from '../../pages/ContactUs';
const Routers = () => {
  return (
    <Switch>
      <Redirect exact from='/' to='/home' />
      <RouteWithLayout
        layout={MainLayout}
        exact
        path='/home'
        component={HomePage}
      />
      <RouteWithLayout
        layout={MainLayout}
        exact
        path='/about-us'
        component={AboutUsPage}
      />
      <RouteWithLayout
        layout={MainLayout}
        exact
        path='/products'
        component={ProductsPage}
      />
      <RouteWithLayout
        layout={MainLayout}
        exact
        path='/products/:id'
        component={ProductDetailPage}
      />
      <RouteWithLayout
        layout={MainLayout}
        exact
        path='/product-care'
        component={ProductCarePage}
      />
      <RouteWithLayout
        layout={MainLayout}
        exact
        path='/request-catalog'
        component={RequestCatalogPage}
      />
      <RouteWithLayout
        layout={MainLayout}
        exact
        path='/contact-us'
        component={ContactUsPage}
      />
    </Switch>
  );
};
export default Routers;
