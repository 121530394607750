import * as React from 'react';
import Typography from '@mui/material/Typography';

const CategoryCard = () => {
  const styles = {
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '75%',
    },
    rootCategoryList: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      marginTop: 30,
    },
    categoryItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: 10,
    },
    image: { width: '100%', minWidth: 50, height: 'auto' },
    divider: {
      width: 300,
      height: '0px',
      border: '1px solid rgba(0, 0, 0, 0.3)',
    },
    typography1: {
      fontWeight: 'bold',
      fontSize: '26px',
      color: 'rgba(0, 0, 0, 0.56)',
      marginBottom: 10,
    },
  };

  const itemData = [
    {
      img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
      title: 'Breakfast',
      author: '@bkristastucchio',
    },
    {
      img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
      title: 'Burger',
      author: '@rollelflex_graphy726',
    },
    {
      img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
      title: 'Camera',
      author: '@helloimnik',
    },
    {
      img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
      title: 'Coffee',
      author: '@nolanissac',
    },
    {
      img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
      title: 'Hats',
      author: '@hjrc33',
    },
    {
      img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
      title: 'Honey',
      author: '@arwinneil',
    },
    {
      img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
      title: 'Basketball',
      author: '@tjdragotta',
    },
    {
      img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
      title: 'Fern',
      author: '@katie_wasserman',
    },
    {
      img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
      title: 'Mushrooms',
      author: '@silverdalex',
    },
    {
      img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
      title: 'Tomato basil',
      author: '@shelleypauls',
    },
    {
      img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
      title: 'Sea star',
      author: '@peterlaster',
    },
    {
      img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
      title: 'Bike',
      author: '@southside_customs',
    },
  ];

  const renderCategory = () => {
    const category = itemData.map((item, index) => {
      if (index < 5) {
        return (
          <div style={styles.categoryItem} key={item.img}>
            <img
              style={styles.image}
              src={`${item.img}?w=248&fit=crop&auto=format`}
              alt={item.title}
            />
            <Typography>{item.title}</Typography>
          </div>
        );
      }
      return null;
    });

    return <div style={styles.rootCategoryList}>{category}</div>;
  };

  return (
    <div style={styles.root}>
      <Typography style={styles.typography1}>BY CATEGORY</Typography>
      <div style={styles.divider} />
      {renderCategory()}
    </div>
  );
};

export default CategoryCard;
