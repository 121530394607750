import React from 'react';
import PropTypes from 'prop-types';
import { Topbar, Footer } from './components';

const Main = (props) => {
  const { children } = props;
  return (
    <div>
      <Topbar />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Main;
