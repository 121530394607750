import React, { useState } from 'react';

import Box from '@mui/material/Box';

import { Typography, TextField, Button } from '@mui/material';

const RequestCatalog = () => {
  const styles = {
    rootText: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: 40,
      marginBottom: 30,
    },
    rootTextInput: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 20,
    },
    rootButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: '20%',
    },
    divider: {
      width: '40%',
      height: '0px',
      border: '1px solid rgba(0, 0, 0, 0.3)',
      marginBottom: 40,
    },
    typography1: {
      fontWeight: 'bold',
      fontSize: '2vw',
      color: 'rgba(0, 0, 0, 0.56)',
      marginTop: 30,
      marginBottom: 10,
    },
    typography2: {
      fontSize: '1.8vw',
      color: 'rgba(0, 0, 0, 0.56)',
      marginBottom: '5%',
      marginTop: '5%',
      textAlign: 'center',
    },
    typography3: {
      fontSize: '1.8vw',
      color: 'rgba(0, 0, 0, 0.56)',
      marginBottom: 10,
      marginRight: 10,
    },
    divInputTitle: {
      width: '40%',
      display: 'flex',
      justifyContent: 'end',
    },
    divInputValue: { width: '60%' },
  };

  const [emailValue, setEmailValue] = useState('');
  const [phoneValue, setPhoneValue] = useState('');
  const [firstNameValue, setFirstNameValue] = useState('');
  const [lastNameValue, setLastNameValue] = useState('');
  const [address1Value, setAddress1Value] = useState('');
  const [address2Value, setAddress2Value] = useState('');
  const [cityValue, setCityValue] = useState('');
  const [stateValue, setStateValue] = useState('');
  const [zipCodeValue, setZipCodeValue] = useState('');

  const renderText = () => {
    return (
      <div style={styles.rootText}>
        <Typography style={styles.typography1}>
          GET INSPIRED, GET ON THE LIST
        </Typography>
        <div style={styles.divider} />
        <Typography style={styles.typography2}>
          Sign up to get the Warner Levitzson Teak Furniture Catalog filled with
          great ideas for living in the home you love!
        </Typography>
      </div>
    );
  };

  const renderTextInput = ({ title, value, onChange }) => {
    return (
      <div style={styles.rootTextInput}>
        <div style={styles.divInputTitle}>
          <Typography style={styles.typography3}>{title}</Typography>
        </div>

        <div style={styles.divInputValue}>
          <TextField
            variant='outlined'
            style={{ width: '50%' }}
            value={value}
            InputProps={{ style: { fontSize: '1.8vw' } }}
            onChange={(event) => {
              onChange(event.target.value);
            }}
          />
        </div>
      </div>
    );
  };

  const renderButtonSubmit = () => {
    return (
      <div style={styles.rootButton}>
        <div style={styles.divInputTitle}></div>

        <div style={styles.divInputValue}>
          <Button
            style={{
              backgroundColor: 'grey',
              color: 'black',
              width: '50%',
              height: '50%',
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Box style={{ position: 'relative', top: 0 }}>
      <img
        style={{ width: '100%', height: 'auto' }}
        src='/images/test-3.png'
        alt='logo'
      />
      <Typography
        style={{
          fontSize: 60,
          position: 'absolute',
          top: '80px',
          textAlign: 'center',
          width: '100%',
          color: 'white',
          letterSpacing: 20,
        }}
      >
        REQUEST CATALOG
      </Typography>
      {renderText()}
      {renderTextInput({
        title: 'Email Address: ',
        value: emailValue,
        onChange: (value) => {
          setEmailValue(value);
        },
      })}
      {renderTextInput({
        title: 'Phone Number: ',
        value: phoneValue,
        onChange: (value) => {
          setPhoneValue(value);
        },
      })}
      {renderTextInput({
        title: 'First Name: ',
        value: firstNameValue,
        onChange: (value) => {
          setFirstNameValue(value);
        },
      })}
      {renderTextInput({
        title: 'Last Name: ',
        value: lastNameValue,
        onChange: (value) => {
          setLastNameValue(value);
        },
      })}
      {renderTextInput({
        title: 'Address 1: ',
        value: address1Value,
        onChange: (value) => {
          setAddress1Value(value);
        },
      })}
      {renderTextInput({
        title: 'Address 2: ',
        value: address2Value,
        onChange: (value) => {
          setAddress2Value(value);
        },
      })}
      {renderTextInput({
        title: 'City: ',
        value: cityValue,
        onChange: (value) => {
          setCityValue(value);
        },
      })}
      {renderTextInput({
        title: 'State: ',
        value: stateValue,
        onChange: (value) => {
          setStateValue(value);
        },
      })}
      {renderTextInput({
        title: 'Zip Code: ',
        value: zipCodeValue,
        onChange: (value) => {
          setZipCodeValue(value);
        },
      })}
      {renderButtonSubmit()}
    </Box>
  );
};

export default RequestCatalog;
