import React from 'react';

import Box from '@mui/material/Box';

import ProductList from '../features/productList';
import { Typography } from '@mui/material';

const Products = () => {
  return (
    <Box style={{ position: 'relative', top: 0 }}>
      <img
        style={{ width: '100%', height: 'auto' }}
        src='/images/test-3.png'
        alt='logo'
      />
      <Typography
        style={{
          fontSize: 60,
          position: 'absolute',
          top: '80px',

          textAlign: 'center',
          width: '100%',
          color: 'white',
          letterSpacing: 20,
        }}
      >
        PRODUCTS
      </Typography>
      <ProductList />
    </Box>
  );
};

export default Products;
