import React from 'react';
// import { Chart } from "react-chartjs-2";
// import validate from "validate.js";

// import SignIn from "features/auth/pages/SignIn/SignIn";

// import useGlobal from "globalStore";

// import validators from "./utils/validators";

// import "react-perfect-scrollbar/dist/css/styles.css";
// import "assets/scss/index.scss";

import Routes from './routes';

// validate.validators = {
//   ...validate.validators,
//   ...validators,
// };

const App = () => {
  //   const authState = useGlobal((state) => state.auth)[0];

  return <Routes />;
};

export default App;
