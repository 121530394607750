import React from 'react';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';

const Topbar = () => {
  const styles = {
    typography1: {
      fontWeight: 'bold',
      fontSize: '26px',
      color: 'rgba(0, 0, 0, 0.56)',
      marginBottom: 10,
    },
    buttonMenuItem: {
      fontSize: '12px',
      color: 'black',
    },
    verticalDivider: {
      height: 20,
      width: 0,
      marginLeft: 10,
      marginRight: 10,
      border: '1px solid rgba(0, 0, 0, 0.13',
    },
  };

  const history = useHistory();

  const menus = [
    'HOME',
    'ABOUT US',
    'PRODUCTS',
    'PRODUCT CARE',
    'REQUEST CATALOG',
    'CONTACT US',
  ];

  const handleMenuClick = (value) => {
    console.log(value);
    if (value === 'ABOUT US') {
      history.push('/about-us');
    }
    if (value === 'HOME') {
      history.push('/home');
    }
    if (value === 'PRODUCTS') {
      history.push('/products');
    }
    if (value === 'PRODUCT CARE') {
      history.push('/product-care');
    }
    if (value === 'REQUEST CATALOG') {
      history.push('/request-catalog');
    }
    if (value === 'CONTACT US') {
      history.push('/contact-us');
    }
  };

  const renderMenuTabs = () => {
    const tabs = menus.map((menu, index) => {
      return (
        <>
          <Button
            key={index}
            onClick={() => {
              handleMenuClick(menu);
            }}
            style={styles.buttonMenuItem}
          >
            {menu}
          </Button>
          {index !== menus.length - 1 && <div style={styles.verticalDivider} />}
        </>
      );
    });

    return (
      <div
        style={{
          height: 'auto',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {tabs}
      </div>
    );
  };

  return (
    <div
      style={{
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <img
        style={{ width: 124, height: 124 }}
        src='/images/logo192.png'
        alt='logo'
      />
      <Typography style={styles.typography1}>WARNER LEVITZSON ®</Typography>

      {renderMenuTabs()}
    </div>
  );
};

export default Topbar;
