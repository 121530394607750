import React, { useState } from 'react';

import Box from '@mui/material/Box';

import { Typography, TextField, Button } from '@mui/material';

const ContactUs = () => {
  const styles = {
    rootText: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: 40,
      marginBottom: 30,
    },

    rootDescription: {
      display: 'flex',
      flexDirection: 'column',
      width: '40%',
      paddingTop: '3%',
    },
    rootTextInput: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 20,
    },
    rootButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    divider: {
      width: '40%',
      height: '0px',
      border: '1px solid rgba(0, 0, 0, 0.3)',
      marginBottom: 40,
    },
    typography1: {
      fontWeight: 'bold',
      fontSize: '2vw',
      color: 'rgba(0, 0, 0, 0.56)',
      marginTop: 30,
      marginBottom: 10,
    },
    typography2: {
      fontSize: '1.8vw',
      color: 'rgba(0, 0, 0, 0.56)',
      marginBottom: '5%',
      marginTop: '5%',
      textAlign: 'center',
    },
    typography3: {
      fontSize: '1.5vw',
      color: 'rgba(0, 0, 0, 0.56)',
      marginBottom: 10,
      marginRight: 10,
    },
    typography4: {
      textAlign: 'center',
      fontSize: '1.5vw',
      color: 'rgba(0, 0, 0, 0.56)',
      marginBottom: '10%',
    },
    divInputTitle: {
      width: '40%',
      display: 'flex',
      justifyContent: 'end',
    },
    divInputValue: { width: '60%' },
  };

  const [emailValue, setEmailValue] = useState('');
  const [phoneValue, setPhoneValue] = useState('');
  const [firstNameValue, setFirstNameValue] = useState('');
  const [lastNameValue, setLastNameValue] = useState('');
  const [address1Value, setAddress1Value] = useState('');
  const [address2Value, setAddress2Value] = useState('');
  const [cityValue, setCityValue] = useState('');
  const [stateValue, setStateValue] = useState('');
  const [zipCodeValue, setZipCodeValue] = useState('');

  const renderText = () => {
    return (
      <div style={styles.rootText}>
        <Typography style={styles.typography1}>Contact Us</Typography>
        <div style={styles.divider} />
      </div>
    );
  };

  const renderDescription = () => {
    return (
      <div style={styles.rootDescription}>
        <Typography style={styles.typography3}>
          Warner Levitzson Teak Furniture
        </Typography>
        <Typography style={styles.typography3}>2008-C Yale Street</Typography>
        <Typography style={styles.typography3}>Santa Ana, CA 92704</Typography>
        <Typography style={styles.typography3}>Phone: 714.206.8884</Typography>
        <Typography style={styles.typography3}>
          Email: wlteak@yahoo.com
        </Typography>
      </div>
    );
  };

  const renderTextInput = ({ title, value, onChange }) => {
    return (
      <div style={styles.rootTextInput}>
        <div style={styles.divInputTitle}>
          <Typography style={styles.typography3}>{title}</Typography>
        </div>

        <div style={styles.divInputValue}>
          <TextField
            variant='outlined'
            style={{ width: '100%' }}
            value={value}
            InputProps={{ style: { fontSize: '1vw' } }}
            onChange={(event) => {
              onChange(event.target.value);
            }}
          />
        </div>
      </div>
    );
  };

  const renderTextAreaInput = ({ title, value, onChange }) => {
    return (
      <div style={styles.rootTextInput}>
        <div style={styles.divInputTitle}>
          <Typography style={styles.typography3}>{title}</Typography>
        </div>

        <div style={styles.divInputValue}>
          <textarea
            style={{
              minHeight: 100,
              minWidth: '100%',
              maxWidth: '100%',
              borderRadius: 5,
              fontSize: '1vw',
            }}
            value={value}
            onChange={(event) => {
              onChange(event.target.value);
            }}
          />
        </div>
      </div>
    );
  };

  const renderButtonSubmit = () => {
    return (
      <div style={styles.rootButton}>
        <div style={styles.divInputTitle}></div>

        <div style={styles.divInputValue}>
          <Button
            style={{
              backgroundColor: 'grey',
              color: 'black',
              width: '50%',
              height: '50%',
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    );
  };

  const renderField = () => {
    return (
      <div
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          width: '40%',
          paddingTop: '3%',
          paddingBottom: '3%',
        }}
      >
        <Typography style={styles.typography4}>
          or use email form below to send us a message
        </Typography>
        {renderTextInput({
          title: 'Email Address: ',
          value: firstNameValue,
          onChange: (value) => {
            setFirstNameValue(value);
          },
        })}
        {renderTextInput({
          title: 'Subject: ',
          value: lastNameValue,
          onChange: (value) => {
            setLastNameValue(value);
          },
        })}
        {renderTextAreaInput({
          title: 'Comments: ',
          value: address1Value,
          onChange: (value) => {
            setAddress1Value(value);
          },
        })}

        {renderButtonSubmit()}
      </div>
    );
  };

  return (
    <Box style={{ position: 'relative', top: 0 }}>
      <img
        style={{ width: '100%', height: 'auto' }}
        src='/images/test-3.png'
        alt='logo'
      />
      <Typography
        style={{
          fontSize: 60,
          position: 'absolute',
          top: '80px',
          textAlign: 'center',
          width: '100%',
          color: 'white',
          letterSpacing: 20,
        }}
      >
        REQUEST CATALOG
      </Typography>
      {renderText()}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: 'auto',
          justifyContent: 'center',
          marginBottom: '5%',
        }}
      >
        {renderDescription()}
        <div style={{ height: 'auto', width: 1, backgroundColor: 'black' }} />
        {renderField()}
      </div>
    </Box>
  );
};

export default ContactUs;
